<template>
  <list-container-widget
    id="questions"
    ref="questionCard"
    :title="widgetName"
    :app-id="30"
    stretch
    class="d-flex"
    :view-more-location="getLocation('questions')"
    :size="getSize('questions')"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="questions" />
      <CreateNewButton item-type="questions" />
    </template>
    <b-spinner
      v-if="isLoading"
      type="grow"
      small
      class="mx-auto d-block"
    />
    <template v-else>
      <div
        v-if="items && items.length > 0"
        id="scrollContainer"
        ref="container"
        style="white-space: normal !important;"
        class="x-scroll-container w-100 list-group mt-1 overflow-auto"
      >
        <!-- Items loop -->
        <question-component-list :is-widget="true" :is-small-widget="isSmallWidget" />
        <b-spinner
          v-if="isLoadingNextPage"
          type="grow"
          small
          class="mx-auto my-2 d-block"
        />
        <b-button
          v-else-if="items && items.length < total"
          class="d-block text-center m-auto"
          variant="link"
          :disabled="isLoadingNextPage"
          @click="handleLoadOfNewItems"
        >
          {{ $t("action.load-more") }}
        </b-button>
      </div>
      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="questionsPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: widgetName }) }}
          </p>
        </b-col>
      </b-row>

      <!-- Input width button -->
      <b-form
        v-if="canCreate"
        ref="questionForm"
        class="chat-app-form d-flex py-1 w-100 border-top"

        @submit.prevent="handleCreateQuestion"
      >
        <b-input-group class="input-group-merge form-send-message mr-1">
          <b-form-input
            v-model="questionName"
            :placeholder="$t('questions.message')"
          />
        </b-input-group>
        <b-button variant="primary" type="submit" style="width: 190px">
          {{ $t("send.button") }}
        </b-button>
      </b-form>
    </template>
  </list-container-widget>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import OrderBy from '@/@core/constants/OrderBy';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import QuestionsPlaceholder from '@/assets/images/placeholders/light/Preguntas.svg';
// import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import QuestionCard from '@/views/apps/questions/components/QuestionCard.vue';
import QuestionComponentList from '../components/QuestionsComponentList.vue';

export default {
  name: 'QuestionListWidget',
  components: {
    QuestionComponentList,
    ListContainerWidget,
    QuestionCard,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
    CreateNewButton: () => import ("@/@core/components/widget-actions/CreateNewButton.vue"  /* webpackChunkName: "CreateNewButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      projects: [],
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      questionName: '',
    };
  },
  computed: {
    isStaff() {
      return this.collective.isStaff;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    canCreate() {
      return (
        this.isStaff
        || checkPermissions(
          'create',
          'question',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    // Load data from store
    itemsData() {
      const items = this.$store.getters.questions?.unpaginated;

      return items;
    },
    items() {
      return [...this.itemsData].sort((a, b) => b.totalLikes - a.totalLikes);
    },
    total() {
      this.totalItems = this.itemsData.length;
      return this.itemsData.length;
    },
    questionsPlaceholder() {
      return QuestionsPlaceholder;
    },
    isSmallWidget() {
      return this.$refs.questionCard.$el.clientWidth < 550;
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData(true);
    // this.getWidth();
    this.isLoading = false;
  },
  methods: {
    /*     getWidth() {
      console.log(this.$refs.questionCard.$el.clientWidth);
      return this.$refs.questionCard.$el.clientWidth;
    }, */
    canViewQuestions(item) {
      if (
        item?.createdByUser?.key === this.loggedUser.key
        || this.isStaff
        || checkPermissions(
          'view',
          'question',
          this.loggedMemberRoles,
          this.collective,
        )
        || checkPermissions(
          'view',
          'question',
          this.loggedMemberRoles,
          this.collective,
        )
      ) {
        return true;
      }
      return false;
    },
    async handleCreateQuestion() {
      if (!this.questionName.trim()) {
        return;
      }
      try {
        const response = await this.$store.dispatch('createQuestion', {
          question: { name: this.questionName },
        });

        this.$socket.emit('newData', {
          itemType: 'questions',
          data: response.data,
          communitySlug: this.$store.getters.currentCollective.slug,
        });

        // this.fetchData();
        this.$refs.questionForm.reset();
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async fetchData(forceAPICall = false) {
      await this.$store.dispatch('getItems', {
        itemType: 'questions',
        page: this.lastLoadedPage,
        forceAPICall,
        orderByCreationTime: OrderBy.DESC,
        requestConfig: { answerCount: 5, count: 100 },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    // TODO: fix onScroll
    async onScroll(event) {
      const distanceToEnd = event.srcElement.scrollHeight
        - event.srcElement.scrollTop
        - event.srcElement.offsetHeight;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length <= total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
  },
};
</script>
